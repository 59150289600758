export enum InboxMessageTitles {
  PluginLoaded = 'plugin_loaded',
  PluginInited = 'plugin_inited',
  CustomerDetailsSection = 'customer_details_section',
  PutMessage = 'put_message',
  PutMessageboxMessage = 'put_messagebox_message',
  SendCards = 'send_cards',
  SetFullscreenWidgetNotificationBadge = 'set_fullscreen_widget_notification_badge',
  NavigateFromFullScreenWidget = 'navigate_from_fullscreen_widget',
  SetReportsFilters = 'set_reports_filters',
  Redirect = 'redirect',
  WidgetReadyForReceivingEvents = 'widget_ready_for_receiving_events', // event should be triggered by widget is fully loaded and it's ready to handle incoming events
}
