import { type ApplicationsAction, ApplicationsActionNames } from './actions';
import { type IApplicationsState } from './interfaces';

export const initialState = {
  loadedWidgets: [],
  loadedFullscreenWidgets: [],
  widgetsEnabled: true,
  widgetsReadyForReceivingEvents: [],
};

export const applicationsReducer = (
  state: IApplicationsState = initialState,
  action: ApplicationsAction,
): IApplicationsState => {
  switch (action.type) {
    case ApplicationsActionNames.LOAD_WIDGET:
      if (!state.loadedWidgets.includes(action.payload.id)) {
        return {
          ...state,
          loadedWidgets: [...state.loadedWidgets, action.payload.id],
        };
      }

      return state;

    case ApplicationsActionNames.LOAD_FULLSCREEN_WIDGET:
      if (!state.loadedFullscreenWidgets.includes(action.payload.id)) {
        return {
          ...state,
          loadedFullscreenWidgets: [...state.loadedFullscreenWidgets, action.payload.id],
        };
      }

      return state;

    case ApplicationsActionNames.WIDGETS_DISABLE: {
      return {
        ...state,
        widgetsEnabled: false,
      };
    }

    case ApplicationsActionNames.WIDGET_READY_FOR_RECEIVING_EVENTS:
      if (!state.widgetsReadyForReceivingEvents.includes(action.payload)) {
        return {
          ...state,
          widgetsReadyForReceivingEvents: [...state.widgetsReadyForReceivingEvents, action.payload],
        };
      }

      return state;

    case ApplicationsActionNames.RESET_WIDGETS_READY_FOR_RECEIVING_EVENTS:
      return {
        ...state,
        widgetsReadyForReceivingEvents: [],
      };

    default:
      return state;
  }
};
